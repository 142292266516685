import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Search,
  ChevronDown,
  Calendar,
  AlignLeft,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { platforms, useWindowSize } from "../../utils";
import { SelectIconFilter } from "../../assets/icon";
import { SelectAuth } from "../../redux/selectors";
import request, { base_url, storage_url } from "../../utils/request";

const MainContainer = styled.div`
  width: 100%;
  max-width: ${(props) => props.size - 100}px;
  margin: 0 auto;
  font-family: "Lato", sans-serif;
  padding: 10px 2rem;

  overflow-y: auto;
  height: 100vh;
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #535ce8;
  font-size: 14px;
  cursor: pointer;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const SearchInputWrapper = styled.div`
  position: relative;
`;

const SearchInput = styled.input`
  padding: 8px 12px 8px 32px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  width: 200px;
`;

const SearchIcon = styled(Search)`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #bcc1ca;
`;

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledSelect = styled.select`
  appearance: none;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 8px 36px 8px 36px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  width: 100%;

  &:focus {
    outline: none;
    border-color: #5469d4;
  }
`;

const SelectIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const ArrowIcon = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const DateRangeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  min-width: 240px;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-bottom: 5rem;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #565e6c;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #4a5261;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }

  scrollbar-width: thin;
  scrollbar-color: #565e6c transparent;

  -ms-overflow-style: -ms-autohiding-scrollbar;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background: #fafafb;
  border: 1px solid #f3f4f6;
  padding: 12px;
  text-align: left;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #565e6c;
  white-space: nowrap;
`;

const Td = styled.td`
  border: 1px solid #f3f4f6;
  padding: 12px;
  font-size: 14px;
`;

const LoadingTr = styled.tr`
  height: 200px;
`;

const LoadingTd = styled.td`
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
`;

const DatePickerWrapper = styled.div`
  position: relative;
`;

const DatePickerPopup = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  z-index: 1000;
  display: flex;
  gap: 16px;
`;

const DatePickerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateRangeDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const DateRangeInput = styled.input`
  padding: 4px 8px;
  border: 1px solid #bcc1ca;
  border-radius: 4px;
  font-size: 14px;
  width: 120px;
`;

const ApplyButton = styled.button`
  background-color: #5469d4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 8px;
`;

const DatePickerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const MonthYearDisplay = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

const NavButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DaysHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
  margin-bottom: 8px;
`;

const DayLabel = styled.span`
  text-align: center;
  color: #5469d4;
  font-size: 14px;
`;

const DaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
`;

const DayButton = styled.button`
  width: 36px;
  height: 36px;
  border: none;
  background: ${(props) => (props.isSelected ? "#5469d4" : "transparent")};
  color: ${(props) =>
    props.isSelected ? "white" : props.isCurrentMonth ? "black" : "#c0c0c0"};
  border-radius: 50%;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background: ${(props) => (props.isSelected ? "#5469d4" : "#f0f0f0")};
  }
`;

const SubmitButton = styled.button`
  width: 120px;
  height: 40px;
  background: #535ce8;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: #535ce8c2;
  }
`;

const CustomSelect = ({ value, onChange, options, icon: Icon }) => (
  <SelectWrapper>
    <StyledSelect value={value} onChange={onChange}>
      {options.map((option, index) => (
        <option key={index} value={option?.value}>
          {option?.name}
        </option>
      ))}
    </StyledSelect>
    <SelectIcon>
      <SelectIconFilter />
    </SelectIcon>
    <ArrowIcon>
      <ChevronDown size={16} />
    </ArrowIcon>
  </SelectWrapper>
);

const DatePicker = ({ selectedDate, onChange }) => {
  const [currentMonth, setCurrentMonth] = useState(selectedDate.getMonth());
  const [currentYear, setCurrentYear] = useState(selectedDate.getFullYear());

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

  const days = [];
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(new Date(currentYear, currentMonth, i));
  }

  const previousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const nextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  return (
    <div>
      <DatePickerHeader>
        <NavButton onClick={previousMonth}>
          <ChevronLeft size={24} />
        </NavButton>
        <MonthYearDisplay>
          {new Date(currentYear, currentMonth).toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </MonthYearDisplay>
        <NavButton onClick={nextMonth}>
          <ChevronRight size={24} />
        </NavButton>
      </DatePickerHeader>
      <DaysHeader>
        {["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"].map((day) => (
          <DayLabel key={day}>{day}</DayLabel>
        ))}
      </DaysHeader>
      <DaysGrid>
        {Array(firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1)
          .fill(null)
          .map((_, index) => (
            <div key={`empty-${index}`} />
          ))}
        {days.map((day) => (
          <DayButton
            key={day}
            onClick={() => onChange(day)}
            isSelected={day.toDateString() === selectedDate.toDateString()}
            isCurrentMonth={true}
          >
            {day.getDate()}
          </DayButton>
        ))}
      </DaysGrid>
    </div>
  );
};

const DataTable = () => {
  const { user } = SelectAuth();
  const { width } = useWindowSize();
  const [filterTable, setFilterTable] = useState(
    JSON.stringify([{ name: "", value: "" }])
  );
  const [loading, setLoading] = useState(true);
  const [statisticsTable, setStatisticsTable] = useState([]);
  const [roles, setRoles] = useState([]);
  const [offers, setOffers] = useState([]);

  // Fetch offers from the API when the component mounts
  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await request.get("/offers"); // Adjust endpoint
        setOffers(response.data);
      } catch (error) {
        console.error("Error fetching offers:", error);
      } finally {
      }
    };

    fetchOffers();
  }, []);
  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await request.get("/statistics");
        const responseRole = await request.get("/roleUsers");
        setStatisticsTable(response?.data);
        setRoles(responseRole?.data);
      } catch (err) {}
    };

    fetchStatistics();
  }, []);

  const [filters, setFilters] = useState({
    search: "",
    general: "",
    user: "",
    offer: "",
    platform: "",
    dateRange: {
      start: new Date(),
      end: new Date(),
    },
  });

  async function search() {
    setLoading(true);
    const outDate = {
      userId: filters.user,
      offerName: filters.offer,
      platform: filters.platform,
      fromDate: new Date(filters.dateRange.start).toISOString().slice(0, 10),
      toDate: new Date(filters.dateRange.end).toISOString().slice(0, 10),
    };
    try {
      const response = await request.get("/statistics", { params: outDate }); // Adjust endpoint
      setStatisticsTable(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching offers:", error);
    } finally {
      setLoading(false);
    }
  }
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [tempDateRange, setTempDateRange] = useState(filters.dateRange);
  const datePickerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleDateRangeChange = (startOrEnd, date) => {
    setTempDateRange((prev) => ({
      ...prev,
      [startOrEnd]: date,
    }));
  };

  const applyDateRange = () => {
    setFilters((prev) => ({
      ...prev,
      dateRange: tempDateRange,
    }));
    setShowDatePicker(false);
  };

  const formatDateRange = (start, end) => {
    const formatDate = (date) => {
      return date.toLocaleDateString("ru-RU", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    };
    return `${formatDate(start)} - ${formatDate(end)}`;
  };

  return (
    <MainContainer size={width}>
      <Header>
        {/* {filterTable === "Общий" ? (
          <SubmitButton>Общий</SubmitButton>
        ) : (
          <BackButton onClick={() => setFilterTable("Общий")}>
            Вернуться назад
          </BackButton>
        )} */}
        <FilterContainer>
          <SearchInputWrapper>
            <SearchIcon size={16} />
            <SearchInput
              placeholder="Поиск товара..."
              value={filters.search}
              onChange={(e) => handleFilterChange("search", e.target.value)}
            />
          </SearchInputWrapper>
          {["owner"].includes(user?.role) && (
            <>
              <CustomSelect
                value={filterTable}
                // onChange={(e) => handleFilterChange("general", e.target.value)}
                onChange={(e) => {
                  setFilterTable(e.target.value);
                }}
                options={[
                  {
                    name: "Общий",
                    value: JSON.stringify([]),
                  },
                  ...roles.map((item) => ({
                    name: item.name,
                    value: JSON.stringify(item.users),
                  })),
                ]}
                icon={AlignLeft}
              />
              <CustomSelect
                value={filters.user}
                onChange={(e) => handleFilterChange("user", e.target.value)}
                options={[
                  {
                    name: "Выбрать ползватель",
                    value: "",
                  },
                  ...JSON.parse(filterTable)?.map((item) => ({
                    name: item.name,
                    value: item.id,
                  })),
                ]}
                icon={AlignLeft}
              />
            </>
          )}

          {/* {["team_lead"].includes(user?.role) && (
            <CustomSelect
              value={filters.user}
              onChange={(e) => handleFilterChange("personal", e.target.value)}
              options={["Личный", "Мои команда"]}
              icon={AlignLeft}
            />
          )} */}

          <CustomSelect
            value={filters.offer}
            onChange={(e) => handleFilterChange("offer", e.target.value)}
            options={[
              { name: "Выбрать оффер", value: "" },
              ...offers.map((item) => ({
                name: item.offer_name,
                value: item.offer_name,
              })),
            ]}
            icon={AlignLeft}
          />
          <CustomSelect
            value={filters.platform}
            onChange={(e) => handleFilterChange("platform", e.target.value)}
            options={[
              { name: "Выбрать плошадка", value: "" },
              ...platforms.map((item) => ({
                name: item,
                value: item,
              })),
            ]}
            icon={AlignLeft}
          />
          <DatePickerWrapper ref={datePickerRef}>
            <DateRangeButton onClick={toggleDatePicker}>
              <Calendar size={16} />
              {formatDateRange(filters.dateRange.start, filters.dateRange.end)}
              <ChevronDown size={16} />
            </DateRangeButton>
            {showDatePicker && (
              <DatePickerPopup>
                <DatePickerColumn>
                  <DateRangeDisplay>
                    <DateRangeInput
                      type="text"
                      value={tempDateRange.start.toLocaleDateString()}
                      readOnly
                    />
                    <span>-</span>
                    <DateRangeInput
                      type="text"
                      value={tempDateRange.end.toLocaleDateString()}
                      readOnly
                    />
                  </DateRangeDisplay>
                  <DatePicker
                    selectedDate={tempDateRange.start}
                    onChange={(date) => handleDateRangeChange("start", date)}
                  />
                </DatePickerColumn>
                <DatePickerColumn>
                  <DatePicker
                    selectedDate={tempDateRange.end}
                    onChange={(date) => handleDateRangeChange("end", date)}
                  />
                  <ApplyButton onClick={applyDateRange}>Применить</ApplyButton>
                </DatePickerColumn>
              </DatePickerPopup>
            )}
          </DatePickerWrapper>
        </FilterContainer>
        <SubmitButton onClick={search}>Поиск</SubmitButton>
      </Header>

      {/* {filterTable === "Общий" && ( */}
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <Th>ID компании</Th>
              <Th>Название компании</Th>
              <Th>Общее количество заявок</Th>
              <Th>Количество успешно обработанных заявок</Th>
              <Th>Процент эффективности</Th>
              <Th>Источник каналы</Th>
              <Th>Среднее время обработки заявки</Th>
              <Th>Новый</Th>
              <Th>В обработке</Th>
              <Th>Отменен</Th>
              <Th>Депозиты</Th>
              <Th>Закрыт</Th>
              <Th>Переходы</Th>
              <Th>Конверсия</Th>
              <Th>Затраты</Th>
              <Th>Доходы</Th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <LoadingTr>
                <LoadingTd colSpan="15">Загрузка...</LoadingTd>
              </LoadingTr>
            ) : (
              statisticsTable?.map(
                (row, index) =>
                  row?.company
                    ?.toLowerCase()
                    .includes(filters?.search?.toLowerCase()) && (
                    <tr key={index}>
                      <Td>#{row?.id}</Td>
                      <Td>{row?.company}</Td>
                      <Td>{row?.all_requests}</Td>
                      <Td>{row?.status_success_count}</Td>
                      <Td>{row?.percentage_success}</Td>
                      <Td>{row?.channel_source}</Td>
                      <Td>{row?.average_time_difference_in_hours}</Td>
                      <Td>{row?.status_new_count}</Td>
                      <Td>{row?.status_process_count}</Td>
                      <Td>{row?.status_cancel_count}</Td>
                      <Td>{row?.deposits}</Td>
                      <Td>{row?.status_closed_count}</Td>
                      <Td>{row?.deposits}</Td>
                      <Td>{row?.clicks}</Td>
                      <Td>{row?.expenses}</Td>
                      <Td>{row?.income}</Td>
                    </tr>
                  )
              )
            )}
          </tbody>
        </Table>
      </TableWrapper>
      {/* )} */}

      {/* {filterTable === "Площадка" && (
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <Th>ID</Th>
                <Th>Количество полученных заявок</Th>
                <Th>Количество успешно обработанных заявок</Th>
                <Th>Процент по эффективности</Th>
                <Th>Среднее время обработки заявки</Th>
                <Th>Новый</Th>
                <Th>В обработке</Th>
                <Th>Отменен</Th>
                <Th>Внесен депозит</Th>
                <Th>Закрыт</Th>
                <Th>Затраты на продвижение</Th>
                <Th>Доход</Th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <LoadingTr>
                  <LoadingTd colSpan="15">Загрузка...</LoadingTd>
                </LoadingTr>
              ) : (
                sampleDataTurnovers.map((row, index) => (
                  <tr key={index}>
                    <Td>{row.id}</Td>
                    <Td>{row.receivedApplications}</Td>
                    <Td>{row.successfulApplications}</Td>
                    <Td>{row.effectivenessPercentage}</Td>
                    <Td>{row.averageProcessingTime}</Td>
                    <Td>{row.new}</Td>
                    <Td>{row.inProcess}</Td>
                    <Td>{row.cancelled}</Td>
                    <Td>{row.depositMade}</Td>
                    <Td>{row.closed}</Td>
                    <Td>{row.promotionCosts}</Td>
                    <Td>{row.revenue}</Td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </TableWrapper>
      )}

      {filterTable === "Баеры" && (
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <Th>ID</Th>
                <Th>Количество переходов</Th>
                <Th>Конверсия</Th>
                <Th>Количество полученных заявок</Th>
                <Th>Количество успешно обработанных заявок</Th>
                <Th>Процент по эффективности</Th>
                <Th>Среднее время обработки заявки</Th>
                <Th>Новый</Th>
                <Th>В обработке</Th>
                <Th>Отменен</Th>
                <Th>Внесен депозит</Th>
                <Th>Закрыт</Th>
                <Th>Затраты на продвижение</Th>
                <Th>Доход</Th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <LoadingTr>
                  <LoadingTd colSpan="15">Загрузка...</LoadingTd>
                </LoadingTr>
              ) : (
                sampleDatabariyer.map((row, index) => (
                  <tr key={index}>
                    <Td>{row.id}</Td>
                    <Td>{row.transitions}</Td>
                    <Td>{row.conversion}</Td>
                    <Td>{row.receivedApplications}</Td>
                    <Td>{row.successfulApplications}</Td>
                    <Td>{row.effectivenessPercentage}</Td>
                    <Td>{row.averageProcessingTime}</Td>
                    <Td>{row.new}</Td>
                    <Td>{row.inProcess}</Td>
                    <Td>{row.cancelled}</Td>
                    <Td>{row.depositMade}</Td>
                    <Td>{row.closed}</Td>
                    <Td>{row.promotionCosts}</Td>
                    <Td>{row.revenue}</Td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </TableWrapper>
      )}

      {filterTable === "Обработчики" && (
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <Th>ID</Th>
                <Th>Название Площадки</Th>
                <Th>Количество переходов</Th>
                <Th>Конверсия</Th>
                <Th>Количество полученных заявок</Th>
                <Th>Количество успешно обработанных заявок</Th>
                <Th>Процент по эффективности</Th>
                <Th>Среднее время обработки заявки</Th>
                <Th>Новый</Th>
                <Th>В обработке</Th>
                <Th>Отменен</Th>
                <Th>Внесен депозит</Th>
                <Th>Закрыт</Th>
                <Th>Затраты на продвижение</Th>
                <Th>Доход</Th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <LoadingTr>
                  <LoadingTd colSpan="15">Загрузка...</LoadingTd>
                </LoadingTr>
              ) : (
                sampleDataPlatform.map((row, index) => (
                  <tr key={index}>
                    <Td>{row.id}</Td>
                    <Td>{row.platformName}</Td>
                    <Td>{row.transitions}</Td>
                    <Td>{row.conversion}</Td>
                    <Td>{row.receivedApplications}</Td>
                    <Td>{row.successfulApplications}</Td>
                    <Td>{row.effectivenessPercentage}</Td>
                    <Td>{row.averageProcessingTime}</Td>
                    <Td>{row.new}</Td>
                    <Td>{row.inProcess}</Td>
                    <Td>{row.cancelled}</Td>
                    <Td>{row.depositMade}</Td>
                    <Td>{row.closed}</Td>
                    <Td>{row.promotionCosts}</Td>
                    <Td>{row.revenue}</Td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </TableWrapper>
      )} */}
    </MainContainer>
  );
};

export default DataTable;
