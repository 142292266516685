import React, { useCallback } from "react";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import { CSSTransition } from "react-transition-group";
import TaskForm from "./KanbaTaskForm";
import { KanbanCloseIcon } from "../../../assets/icon";

const NotificationList = ({ isOpen, onClose, isEdit, initialData }) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <GlobalStyle isOpen={isOpen} />
      <CSSTransition
        in={isOpen}
        timeout={500}
        classNames="notification-list"
        unmountOnExit
      >
        <Container>
          <ContainerContent>
            <Header>
              <Title>Создать задачу</Title>
              <CloseButton onClick={handleClose}>
                <KanbanCloseIcon size={20} />
              </CloseButton>
            </Header>
            <NotificationsWrapper>
              <TaskForm CloseModal={handleClose} isEdit={isEdit} initialData={initialData} />
            </NotificationsWrapper>
          </ContainerContent>
        </Container>
      </CSSTransition>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: ${(props) => (props.isOpen ? "hidden" : "scroll")};
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ContainerContent = styled.div`
  position: relative;
  z-index: 1000;
  background: #ffffff;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 2px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    width: 2px;
  }

  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  max-width: 590px;
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  z-index: 1000;
  transition: all 500ms ease-in-out;

  &.notification-list-enter {
    opacity: 0;
  }

  &.notification-list-enter-active {
    opacity: 1;
  }

  &.notification-list-exit {
    transform: translateX(0%);
    opacity: 1;
  }

  &.notification-list-exit-active {
    transform: translateX(100%);
    opacity: 0;
  }

  &::after {
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 0;
    content: "";
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
  opacity: 0;
  animation: ${fadeIn} 500ms ease-out forwards;
  animation-delay: 100ms;
`;

const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  transition: color 0.2s;

  &:hover {
    color: #333;
  }
`;

const NotificationsWrapper = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`;

export default NotificationList;
