import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import request from "../../utils/request";
import Modal from "./Modal";

const Container = styled.div`
  font-family: Arial, sans-serif;
  padding: 4rem;
  margin-top: -28px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-family: "Outfit", sans-serif;
  font-size: 32px;
  font-weight: 800;
  color: #171a1f;
  margin: 32px 0 20px 72px;
  margin: 0;
`;

const Select = styled.div`
  position: relative;
  display: inline-block;
`;

const SelectButton = styled.button`
  background-color: #535ce8;
  color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 12px 18px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ArrowIcon = styled.span`
  margin-left: 8px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 360px);
  gap: 15px;
  padding: 0;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Card = styled.div`
  background-color: #f1f2fd;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  height: 200px;
  box-sizing: border-box;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 8px;
    background-color: #4e5af2;
  }

  transition: box-shadow 0.2s, opacity 0.2s, background-color 0.2s;
  opacity: 0;
  animation: ${fadeIn} 300ms ease-out forwards;
  animation-delay: ${(props) => props.index * 100 + 200}ms;

  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
`;

const CardTitle = styled.h3`
  font-size: 14px;
  color: #6c757d;
  margin: 0 0 8px 0;
`;

const OfferName = styled.h2`
  font-size: 24px;
  margin: 0 0 12px 0;
`;

const Description = styled.p`
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 16px;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 21px;
`;

const InfoLabel = styled.span`
  font-weight: bold;
`;

const InfoValue = styled.span`
  color: #6c757d;
`;

const GenerateButton = styled.button`
  background-color: #eefdf3;
  color: #117b34;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 9px;
  cursor: pointer;
  align-self: flex-end;
`;

const OfferCard = ({
  index,
  offer_name,
  comment,
  rate,
  geo,
  openModalLink,
}) => (
  <Card index={index} onClick={openModalLink}>
    <CardTitle>Название оффера</CardTitle>
    <OfferName>{offer_name}</OfferName>
    <Description>{comment}</Description>
    <InfoContainer>
      <InfoColumn>
        <InfoRow>
          <InfoItem>
            <InfoLabel>Ставка</InfoLabel>
            <InfoValue>{rate}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>Геопозиция</InfoLabel>
            <InfoValue>{geo}</InfoValue>
          </InfoItem>
        </InfoRow>
      </InfoColumn>
      <GenerateButton>Генерировать ссылку</GenerateButton>
    </InfoContainer>
  </Card>
);

const OfferGrid = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offerId, setOfferId] = useState(null);

  const openModalLink = (id) => {
    setOfferId(id);
    setIsModalOpen(true);
  };

  const [offers, setOffers] = useState([]);

  // Fetch offers from the API when the component mounts
  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await request.get("/offers"); // Adjust endpoint
        setOffers(response.data);
      } catch (error) {
        console.error("Error fetching offers:", error);
      } finally {
      }
    };

    fetchOffers();
  }, []);

  return (
    <Container>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} offerId={offerId}/>
      <Header>
        <Title>Генерация ссылки</Title>
      </Header>
      <Grid>
        {offers.map((offer, index) => (
          <OfferCard
            key={index}
            index={index}
            {...offer}
            openModalLink={() => {
              openModalLink(offer.id);
            }}
          />
        ))}
      </Grid>
    </Container>
  );
};

export default OfferGrid;
